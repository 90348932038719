@font-face{
  font-family: 'ClashGrotesk-Variable';
  src:  url('../public/ClashGrotesk-Variable.ttf') format('truetype');
}
@font-face{
  font-family: 'ClashGrotesk-Regular';
  src:  url('../public/ClashGrotesk-Regular.otf') format('truetype');
}
@font-face{
  font-family: 'ClashGrotesk-Light';
  src:  url('../public/ClashGrotesk-Light.otf') format('truetype');
}
@font-face{
  font-family: 'ClashGrotesk-Semibold';
  src:  url('../public/ClashGrotesk-Semibold.otf') format('truetype');
}
@font-face{
  font-family: 'ClashGrotesk-Medium';
  src:  url('../public/ClashGrotesk-Medium.otf') format('truetype');
}
@font-face{
  font-family: 'GeneralSans-Variable';
  src:  url('../public/GeneralSans-Variable.ttf') format('truetype');
}
@font-face{
  font-family: 'GeneralSans-Regular';
  src:  url('../public/GeneralSans-Regular.otf') format('truetype');
}
@font-face{
  font-family: 'GeneralSans-Light';
  src:  url('../public/GeneralSans-Light.otf') format('truetype');
}
@font-face{
  font-family: 'GeneralSans-Medium';
  src:  url('../public/GeneralSans-Medium.otf') format('truetype');
}

/*Global*/

.App {
  text-align: center;
  background-color: #131313;
  min-height: 100vh;
  overflow-x: hidden;
}
.body{
  width: 91.5vw;
  display: block;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*HEADER*/



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color : #fcecec;
}

.App-link {
  color: #61dafb;
}

.container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: solid rgba(255,255,255,0.2) 0.5px;
}

.navbar {
  height: 180px;
  background-color: #131313;
  position: relative;
  margin-bottom: 40px;
  font-family: "GeneralSans-Variable";
}

.logo {
  display: flex;
  align-items: baseline;
  color : #fcecec;
  text-align: left;
  font-family: "ClashGrotesk-Variable";
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 220px;
}

.logo img{
  width: 100%;
  height: 300px;
}

.nav-elements ul {
  display: flex;
  justify-content: end;
  list-style-type: none;
  flex-flow: row;
  gap: 50px;
}

.nav-elements ul a {
  font-size: 16px;
  color: #F0F0EE;
  text-decoration: none;
  filter: opacity(75%);
  letter-spacing: 1px;
}

.nav-elements ul a.active {
  color: #F0F0EE;
  position: relative;
  filter: opacity(100%);
}
.nav-elements ul a:hover {
  filter: opacity(100%);
}

/*A PROPOS*/

.aboutContent{
  margin-top: 150px ;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
}

.photoPerso{
  width: 100%;
}

.aboutTextContainer h1{
  margin-top: -40px;
  margin-bottom: 120px;
  text-align: left;
  font-family: "ClashGrotesk-Medium";
  font-size: 120px;
  color: #fcecec;
  letter-spacing: 2px;
  font-weight: lighter;
  text-transform: uppercase;
}

.aboutTextContainer p{
  margin-top: 0;
  text-align: left;
  font-family: "GeneralSans-Medium";
  font-size: 20px;
  color: #fcecec;
  font-weight: 1;
  width: 830px;
  filter: opacity(100%);
  line-height: 25px;
}

.homeHeader{
  color : #fcecec;
  text-align: left;
  font-family: "ClashGrotesk-Medium";
  font-size: 55px;
  line-height: 120px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.homeHeader h1{
  margin-bottom: 15px;
}
.leftImgPortfolio img{
  width: 50%;
}
.leftImgPortfolio {
  color : #fcecec;
  display: flex;
  gap: 60px;
  align-items: end;
  text-align: left;
  font-family: "ClashGrotesk-Medium";
  margin-bottom: 100px;
  flex-wrap: wrap;
  line-height: 20px;
  margin-top: 60px;
  justify-content: left;
  position: relative;
  left: -90px;
}
.leftImgPortfolio h3{
  font-size: 50px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
}
.leftImgPortfolio p{
  font-family: "GeneralSans-Light";
  font-size: 16px;
  filter: opacity(100%);
  font-weight: lighter;
  line-height: 25px;
  letter-spacing: 1px;
}
.leftImgPortfolio a{
  display: block;
  color : #fcecec;
  text-decoration: none;
  border : solid white 1px;
  border-radius: 100px;
  padding: 5px 15px 5px 15px;
  width: fit-content;
  text-align: center;
  font-family: "GeneralSans-Light";
  font-size: 16px;
  letter-spacing: 1px;
  float: right;
  font-weight: lighter;
  filter: opacity(75%);
}
.leftImgPortfolio a:hover{
  cursor: pointer;
  filter: opacity(100%);
}
.rightImgPortfolio img{
  width: 47.5%;
}
.rightImgPortfolio {
  color : #fcecec;
  display: flex;
  gap: 60px;
  align-items: end;
  text-align: left;
  font-family: "ClashGrotesk-Medium";
  margin-bottom: 100px;
  flex-wrap: wrap;
  line-height: 20px;
  width: 100vw;
  position: relative;
  left: -90px;
  justify-content: end;
  font-weight: bolder;
  margin-top: 60px;
}

.rightImgPortfolio h3{
  font-size: 50px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
}
.rightImgPortfolio p{
  font-family: "GeneralSans-Light";
  font-size: 16px;
  filter: opacity(100%);
  font-weight: lighter;
  line-height: 25px;
  letter-spacing: 1px;
}
.rightImgPortfolio a{
  display: block;
  color : #fcecec;
  text-decoration: none;
  border : solid white 1px;
  border-radius: 100px;
  padding: 5px 15px 5px 15px;
  width: fit-content;
  text-align: center;
  font-family: "GeneralSans-Light";
  font-size: 16px;
  letter-spacing: 1px;
  float: right;
  font-weight: lighter;
  filter: opacity(75%);
}
.rightImgPortfolio a:hover{
  cursor: pointer;
  filter: opacity(100%);
}
.portfolioHomeContainer {
  display: flex;
  flex-flow: column;
  gap: 80px;
  margin-top: -50px;
}
.globalPortfolioLink{
  display: flex;
  justify-content: center;
}
.globalPortfolioLink a {
  color: #fcecec;
  text-decoration: none;
  border: solid rgba(255, 255, 255, 0.2) 0.5px;
  border-radius: 100px;
  padding: 17px 27px 15px 27px;
  width: fit-content;
  text-align: center;
  font-family: "ClashGrotesk-Medium";
  font-size: 30px;
  margin-top: 50px;
  letter-spacing: 2px;
  margin-bottom: 50px;
  font-weight: 500;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  gap: 25px;
  align-items: center;
  filter: opacity(0.5);
}

.globalPortfolioLink a:hover {
  filter: opacity(1);
}
.prestationsHomeContainer{
  margin-top: -100px;
}
.prestationsHomeContainer p{
  color:white;
  font-family: "GeneralSans-Variable";
  text-align: left;
  font-size: 50px;

 }
.allPrestation{
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: end;
  margin-top:50px;
}
.uniquePrestation{
  width: 540px;
}
.uniquePrestation .prestationTitle{
  font-family: "ClashGrotesk-Medium";
  color : #fcecec;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: -5px;
  margin-top: 0;
  margin-top: 0;
  text-transform: uppercase;
  line-height: 50px;
}
.uniquePrestation .prestationText{
  color:white;
  font-size: 16px;
  filter: opacity(100%);
  font-family: "GeneralSans-Light";
  font-size: 16px;
  line-height: 24px;
}
.portfolioLink{
  text-decoration: none;
  color : #fcecec;
  text-transform: uppercase;
  font-family: "GeneralSans-Variable";
  font-weight: bold;
  font-size: 30px;
  border: solid white 3px;
  width: 300px;
  display: block;
  margin: auto;
  border-radius: 100px;
  padding: 5px;
}

.portfolioLink:hover{
  background-color : #fcecec;
  color: #131313;
  cursor: pointer;
}
.footerContainer{
  padding-top: 30px;
  font-family: "GeneralSans-Variable";
  border-top: solid rgba(255,255,255,0.2) 0.5px;
  margin-top: 100px;
  line-height: 10px;
  margin-bottom: 50px;
}
.footerHeader{
  color : #fcecec;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.inputText {
  width: 46%;
  height: 45px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 25px;
  color: lightgray;
  font-size: 16px;
  font-family: "GeneralSans-Regular";
}
.inputTextArea {
   width: 100%;
   height: 130px;
  border-radius: 10px;
  padding: 10px;
  padding-left: 25px;
  color: lightgray;
  font-size: 16px;
  font-family: "GeneralSans-Regular";
 }
.inputSubmit{
  margin-top: 60px;
  margin-bottom: 100px;
  font-size: 16px;
  background: none;
  border: white 3px solid;
  color : #fcecec;
  filter: opacity(75%);
  font-family: "GeneralSans-Light";
  width: 240px;
  padding: 20px;
  border-radius: 100px;
}
.footerLink{
  display: flex;
  flex-flow: column;
  line-height: 24px;
  justify-content: right;
  text-align: right;
  margin-top: 30px;
}
.footerLink a{
  font-family: "GeneralSans-Variable";
  color:white;
  text-decoration: none;
  font-size: 21px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.footerForm form {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: end;
}
.footerForm{
  width: 1140px;
}
.logoRs{
  display: flex;
  gap: 50px;
  justify-content: end;
  margin-bottom: 20px;
}
.logoRs img{
  width: 35px;
}
.projectHeader{
  align-items:flex-end;
  width:100%;
  display:flex
}
.projectHeader{
  align-items:start;
  width:100%;
  display:flex;
  gap: 130px;
  margin-top: 70px;
}
.projectHeader h1{
  margin-top: 70px;
  margin-bottom: 30px;
  text-align: left;
  font-family: "ClashGrotesk-Medium";
  font-size: 120px;
  color : #fcecec;
  letter-spacing: 2px;
  font-weight: lighter;
  text-transform: uppercase;
}
.projectHeader p {
  width: 480px;
  line-height: 20px;
  font-size: 16px;
  font-family: "GeneralSans-Light";
  letter-spacing: 1px;
  filter: opacity(100%);
  color: #fcecec;
  text-align: left;
  padding-top: 60px;
}

#projetImgContainer{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
#projetImgContainer img{
   width: 83% ;
 }
.titleFooter {
  text-align: left;
  font-size: 50px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
  text-transform: uppercase;
  line-height: 50px;
}
@media (max-width: 1540px) {
  .globalPortfolioLink a{
    margin: auto;
    width: 200px;
    font-size: 25px;
    height: 50px;
    gap: 20px;
  }
  .globalPortfolioLink img{
    width: 30px!important;
    height: 30px!important;
  }
}
@media (max-width: 1380px) {
  .allPrestation {
    margin-top: 100px;
  }
}
@media (max-width: 1250px) {
  .footerForm {
    width: 100%;
  }
  .footerForm form {
    justify-content: center;
  }
  .allPrestation {
    margin: 0;
    justify-content: center;
    margin-top: 100px;
  }
  .uniquePrestation {
    width: 400px;
  }
}
@media (max-width: 1220px) {
  .projectHeader {
    gap: 10px;
    margin-top: 0;
    flex-wrap: wrap;
  }
  .projectHeader h1 {
    font-size: 60px;
  }
  .projectHeader p {
    padding-top: 0px;
  }
  .aboutContent {
    justify-content: center;
  }
  .aboutContent h1{
   font-size: 60px;
    margin-bottom: 0;
  }
  .aboutTextContainer{width: 100%!important}
  .aboutTextContainer p{width: 100%!important}
  .homeHeader {
    color : #fcecec;
    text-align: left;
    font-family: "ClashGrotesk-Medium";
    font-size: 25px;
    line-height: 60px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .homeHeader img {
    width: 50px!important;
  }
  .rightImgPortfolio{
    left: -20px;
    justify-content: center;
  }
  .rightImgPortfolio div{
    width: 90%!important;
    display: block;
    margin: auto;
  }
  .rightImgPortfolio img{
    width: 300px;
  }
  .leftImgPortfolio{
    left: -20px;
    justify-content: center;
    flex-flow: column-reverse;
    position: initial;
  }
  .leftImgPortfolio div{
    width: 90%!important;
    display: block;
    margin: auto;
  }
  .leftImgPortfolio img{
    width: 300px;
    margin: auto;
  }
  .footerContainer {
     padding-top: 30px;
     font-family: "GeneralSans-Variable";
     border-top: white 3px solid;
     margin-top: 100px;
     line-height: 35px;
   }
}
@media (max-width: 920px) {
  #projetImgContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 720px) {
  .nav-elements ul {
    display: flex;
    justify-content: end;
    list-style-type: none;
    flex-flow: column;
    gap: 0;
  }
}
@media (max-width: 650px) {
  .logoRs {
    flex-wrap: wrap!important;
  }
  .inputText {
    width: 100%;
  }
}
@media (max-width: 470px) {
  .nav-elements ul a {
    font-size: 17px;
    color: #F0F0EE;
    text-decoration: none;
    filter: opacity(75%);
    letter-spacing: 1px;
  }
  .nav-elements ul {
    display: flex;
    justify-content: end;
    list-style-type: none;
    flex-flow: column;
    width: 80px;
  }
  .inputText {
    width: 100%;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
